import { For } from "solid-js";
import { emojiFace1, emojiFace2, emojiFace3 } from "~/assets/assets";
import { classNames } from "~/utils/etc";
import { Divider } from "~/widgets/divider";
import { PhosphorIcon } from "~/widgets/icons";

export function TrustMarkerHappyUsers() {
  return (
    <div
      class="h-fit w-full rounded-2xl border border-neutral-200 bg-baseTertiaryLight p-1"
      style={{ "box-shadow": "0px 2px 8px 0px rgba(0, 0, 0, 0.04);" }}
    >
      <div class="h-fit w-full rounded-xl border border-gray-100 bg-gradient-to-r from-amber-50 to-baseTertiaryLight">
        <TopSection />
        <Divider />
        <BottomSection />
      </div>
    </div>
  );
}

export function TopSection() {
  return (
    <div class="flex flex-col gap-3 p-4 lg:flex-row lg:items-center lg:gap-10">
      <div class="lg:hidden">
        <RandomEmojiFaces />
      </div>
      <div class="flex w-full flex-row gap-4 text-basePrimaryDark ">
        <div class="flex flex-col">
          <span class="text-h4 lg:text-h5">5L+</span>
          <span class="text-medium">Happy Hubble users</span>
        </div>
        <div class="flex flex-col">
          <span class="text-h4 lg:text-h5">₹10Cr</span>
          <span class="text-medium lg:hidden">Saved till date</span>
          <span class="hidden text-medium lg:inline-block">
            Saved till date with gift cards
          </span>
        </div>
        <div class="hidden lg:ml-auto lg:flex">
          <RandomEmojiFaces />
        </div>
      </div>
    </div>
  );
}

const promises = [
  {
    text: "Instant gift card e-delivery",
    icon: "lightning",
  },
  {
    text: "Guaranteed savings",
    icon: "piggy-bank",
  },
  {
    text: "Safe & secure payment",
    icon: "shield-check",
  },
  {
    text: "Zero Hassle support",
    icon: "headset",
  },
];

function BottomSection() {
  return (
    <div class="flex flex-col gap-3 p-4">
      <div
        class="text-f12Bold uppercase leading-none tracking-tight text-[#9F784B] "
        style={{ "letter-spacing": "0.36px" }}
      >
        Hubble promise
      </div>
      <div class="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-x-6 lg:gap-y-2">
        <For each={promises}>
          {(promise) => (
            <div class="inline-flex flex-row items-center gap-1">
              <PhosphorIcon
                fontSize={15}
                name={promise.icon}
                size="fill"
                class="text-[#BC8C53]"
              />
              <div class="text-mediumBold text-basePrimaryDark">
                {promise.text}
              </div>
            </div>
          )}
        </For>
      </div>
    </div>
  );
}

export function RandomEmojiFaces(props: {
  styles?: { emoji?: string; container?: string };
}) {
  return (
    <div
      class={classNames(
        props.styles?.container,
        "inline-flex h-10 w-28 items-center justify-start -space-x-1.5 "
      )}
    >
      <img
        class={classNames(
          props.styles?.emoji,
          "h-10 w-10 rounded-full border border-baseTertiaryLight ring-1 ring-baseTertiaryLight"
        )}
        src={emojiFace1}
        alt="Facemoji"
      />
      <img
        class={classNames(
          props.styles?.emoji,
          "h-10 w-10 rounded-full border border-baseTertiaryLight ring-1 ring-baseTertiaryLight"
        )}
        src={emojiFace2}
        alt="Facemoji"
      />
      <img
        class={classNames(
          props.styles?.emoji,
          "h-10 w-10 rounded-full border border-baseTertiaryLight ring-1 ring-baseTertiaryLight"
        )}
        src={emojiFace3}
        alt="Facemoji"
      />
    </div>
  );
}
